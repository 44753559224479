import React, { useEffect, useState } from 'react'
import './styles.css'
import { BsGoogle } from 'react-icons/bs'
import { HiOutlineUserCircle, HiOutlineMail } from 'react-icons/hi'
import { PiPasswordBold } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import { signInUser, signInUserWithGoogle } from '../firebase'
import { validEmail, validPassword } from '../helpers/validators'

import logo from '../icons/Xpense_NX192.png';

function Login(props) {
    const navigate = useNavigate();

    const [registerMode, setRegisterMode] = useState(false)

    useEffect(() => {
        props.setIsAlertOpen(false);

        document.getElementsByClassName('footerIndicatorGradient')[0].className = 'footerIndicatorGradient left'

        return () => {

        }
    }, [])


    return (
        <>
            <div className='page loginContainer'>
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                </header>
                <span className="title">
                    Xpense NX
                </span>
                <span className='subtitle'>
                    Your Personal Expense Tracker
                </span>
                <form className='loginRegisterForm'>
                    <span className="sectionHeading">
                        {registerMode ? 'Register' : 'Login'}
                    </span>
                    <div className={registerMode ? "inputIconed" : "inputIconed collapsed"}>
                        <div className="icon">
                            <HiOutlineUserCircle />
                        </div>
                        <input required type="text" placeholder='Name' />
                    </div>
                    <div className={registerMode ? "inputIconed" : "inputIconed collapsed"}>
                        <div className="icon">
                            <HiOutlineUserCircle />
                        </div>
                        <input type="text" placeholder='Nick Name (Optional)' />
                    </div>
                    <div className="inputIconed">
                        <div className="icon">
                            <HiOutlineMail />
                        </div>
                        <input required type="email" id='email_nx_Login' placeholder='Email Address' />
                    </div>
                    <div className="inputIconed">
                        <div className="icon">
                            <PiPasswordBold />
                        </div>
                        <input required type="password" id='pwd_nx_Login' placeholder='Password' />
                    </div>
                    <div className={registerMode ? "inputIconed" : "inputIconed collapsed"}>
                        <div className="icon">
                            <PiPasswordBold />
                        </div>
                        <input required type="password" placeholder='Confirm Password' />
                    </div>
                    <div className={registerMode ? 'buttonGroup reversed' : 'buttonGroup'}>
                        <button className={registerMode ? 'alt2' : ''} onClick={(e) => {
                            e.preventDefault();
                            if (!registerMode) {
                                setRegisterMode(true)
                            }
                            else {
                                // Register operations
                            }
                        }}>
                            {registerMode ? 'Register' : 'Sign Up'}
                        </button>
                        <button className={registerMode ? '' : 'alt2'} onClick={async (e) => {
                            e.preventDefault();
                            if (registerMode) {
                                setRegisterMode(false)
                            }
                            else {
                                const userEmail = document.getElementById('email_nx_Login').value;
                                const userPwd = document.getElementById('pwd_nx_Login').value;

                                const validCredantials = validEmail(userEmail) && validPassword(userPwd)

                                if (validCredantials) {
                                    const res = await signInUser(userEmail, userPwd)
                                    props.RaiseAlert({
                                        title: "Logging In...",
                                        body: null,
                                        closeActionText: ''
                                    });
                                    if (res[0]) {
                                        navigate('/')
                                    }
                                    else {
                                        props.RaiseAlert({
                                            title: "Error",
                                            body: res[1],
                                            closeActionText: 'Close'
                                        });
                                        // alert(res[1])
                                    }
                                }
                                else {
                                    props.RaiseAlert({
                                        title: "Error",
                                        body: "Invalid Credentials",
                                        closeActionText: 'Ok'
                                    });
                                }

                            }
                        }}>
                            {registerMode ? 'I have an Account' : 'Login'}
                        </button>
                    </div>
                    <div className='altLoginContainer'>
                        <button className="icon" onClick={async (e) => {
                            e.preventDefault()

                            props.RaiseAlert({
                                title: "Sign In With Google",
                                body: "Authenticating...",
                                closeActionText: null
                            });

                            const res = await signInUserWithGoogle()
                            if (res[0]) {
                                navigate('/')
                            }
                            else {
                                props.setIsAlertOpen(false)
                                // props.RaiseAlert({
                                //     title: "Error",
                                //     body: res[1],
                                //     closeActionText: 'Close'
                                // });
                            }
                        }}>
                            <BsGoogle />
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Login