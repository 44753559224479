import React, { useEffect, useState } from 'react'
import { HiPlus, HiOutlineEye, HiOutlineRefresh } from 'react-icons/hi'
import { HiOutlineEyeSlash } from 'react-icons/hi2'
import { BsCurrencyRupee, BsArrowReturnRight } from 'react-icons/bs'

import './style.css'
import { useNavigate } from 'react-router-dom'
import UpdateLimits from '../profile/components/UpdateLimits'
import { getCurrentUserDisplayName, isUserSignedIn } from '../firebase'
import UpdateACBalance from '../profile/components/UpdateACBalance'

function Home(props) {
    const navigate = useNavigate();
    
	const [uname, setUname] = useState("");

    const [showACDetails, setShowACDetails] = useState(false);

    const [expensesAC, setExpensesAC] = useState("0.00");
    const [savingsAC, setSavingsAC] = useState("0.00");
    const [salaryAC, setSalaryAC] = useState("0.00");

    useEffect(() => {
        props.setIsAlertOpen(false);
        startUpCheck();

        return () => {

        }
    }, [])

    async function startUpCheck(){
        const signIncheck = await isUserSignedIn();
        if(signIncheck == null){
            navigate('/login')
        }
        
		// Load User Display Name
		setUname( await getCurrentUserDisplayName() );
    }


    return (
        <div className='page homeContainer'>
            <div className="greetings">
                <h3>
                    Welcome{uname !== "" ? ", " + uname : ""}.
                </h3>
            </div>
            <div className="monthlySummaryContainer">
                <h4>
                    Monthly Summary
                </h4>
                <div className="monthlySummary">
                    <button title='Refresh Monthly Summary' className='refreshSummaryBtn overlay accent' onClick={() => {
                        document.getElementsByClassName('refreshSummaryBtn')[0].classList.remove('refreshing')
                        setTimeout(() => {
                            document.getElementsByClassName('refreshSummaryBtn')[0].classList.add('refreshing')
                        }, 1);
                    }}> <HiOutlineRefresh /> </button>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'stretch',
                        gap: '5px',
                        flex: '1'
                    }}>
                        <h1>
                            50%
                        </h1>
                        <small>
                            <b>Used</b>
                        </small>
                    </div>
                    <div className='monthlySummaryDetails'>
                        <h5>
                            Used:
                        </h5>
                        <div>
                            ₹ 12,597.22 / ₹ 25,000.00
                        </div>
                        <h5>
                            Remaining:
                        </h5>
                        <div>
                            ₹ 12,402.78 / ₹ 25,000.00
                        </div>
                    </div>
                </div>
            </div>
            <div className="quickActionsContainer">
                <h4>
                    Quick Actions
                </h4>
                <div className="quickActions">
                    <button onClick={() => {
                        navigate('/finances/register-transaction?t=expense')
                        // document.getElementsByClassName('footerIndicatorGradient')[0].className = 'footerIndicatorGradient middle'
                    }}>
                        <HiPlus />
                        Add Expense
                    </button>
                    <button onClick={() => {
                        navigate('/finances/register-transaction?t=refund')
                        // document.getElementsByClassName('footerIndicatorGradient')[0].className = 'footerIndicatorGradient middle'
                    }}>
                        <BsArrowReturnRight />
                        Add Refund
                    </button>
                    <UpdateACBalance />
                    <UpdateLimits />
                </div>
            </div>
            <div className="accountsSummaryContainer">
                <h4>
                    Accounts Summary
                </h4>
                <div className="accountsSummary">
                    <button className='showACDetails overlay' title='Toggle AC Balance Visibility' onClick={() => {
                        setShowACDetails(!showACDetails)
                    }}> {showACDetails ? <HiOutlineEye /> : <HiOutlineEyeSlash />} </button>
                    <span>Expense A/C : <b>₹ {showACDetails ? expensesAC : "xxxxx.xx"}</b> </span>
                    <span>Savings A/C : <b>₹ {showACDetails ? savingsAC : "xxxxx.xx"}</b> </span>
                    <span>Salary A/C : <b>₹ {showACDetails ? salaryAC : "xxxxx.xx"}</b> </span>
                </div>
            </div>
        </div>
    )
}

export default Home