import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom';

function Accounts(props) {
	const [subPageEditMode, setsubPageEditMode, subPageConfirm, subPageReject] = useOutletContext();

  useEffect(() => {
    props.RaiseAlert({
      title: 'Error Alert Testing',
      body: 'Error Alert Body Test',
      closeActionText: "Retry",
      alertType: 'Error'
    })
  
    return () => {
      
    }
  }, [])
  

  return (
    <div className='subpage'>
        Accounts
    </div>
  )
}

export default Accounts