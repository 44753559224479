import React from 'react'
import Popup from 'reactjs-popup';
import { BsCurrencyRupee } from 'react-icons/bs'

import { AiOutlinePieChart } from 'react-icons/ai'
import { HiOutlineArrowLeft } from 'react-icons/hi'

function UpdateACBalance() {
  return (
    <Popup trigger={
      <button>
          <BsCurrencyRupee />
          Update Balances
      </button>
  }
  modal
  >
      {close => 
          <div className='modal'>
              <h4 className='modal-header'>
                  <button className='overlay icon' onClick={close}>
                      <HiOutlineArrowLeft />
                  </button>
                  Update Account Balances
              </h4>
              Update Balances here.
          </div>
      }
  </Popup>
  )
}

export default UpdateACBalance