import React, { useEffect, useState } from 'react'
import { getCurrentUserDisplayName, signOutUser } from '../../firebase'
import UserMenuItem from '../components/UserMenuItem'

import { FiLogOut } from 'react-icons/fi'
import { PiUserBold } from 'react-icons/pi'
import { MdOutlineAccountBalanceWallet } from 'react-icons/md'
import { TbCurrencyRupee } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

function ProfileMenu(props) {
    const navigate = useNavigate();

    const [uname, setUname] = useState("");

    useEffect(() => {
        startUpCheck();

        return () => {

        }
    }, [])

    async function startUpCheck() {
        // Load User Display Name
        setUname(await getCurrentUserDisplayName());
    }

    const nickname = "Raveendra"

    const menuItems = [
        {
            "title": "Profile",
            "subtitle": "Name, Nickname",
            "icon": <PiUserBold />,
            "action": () => {
                navigate('/profile/user')
             }
        },
        {
            "title": "Accounts",
            "subtitle": "Expense, Savings and Salary Account",
            "icon": <MdOutlineAccountBalanceWallet />,
            "action": () => { 
                navigate('/profile/accounts')
            }
        },
        {
            "title": "Spends and Limits",
            "subtitle": "Overall Limits, Spend Categories",
            "icon": <TbCurrencyRupee />,
            "action": () => { }
        },
        {
            "title": "Log Out",
            "subtitle": "Sign out of your Account",
            "icon": <FiLogOut />,
            "action": async () => {
                props.RaiseAlert({
                    title: "Signing Out...",
                    body: null,
                    closeActionText: 'Close'
                })
                await signOutUser();
                navigate('/login')
            }
        }
    ]

    return (
        <div className='subpage'>
            <div className="greetings">
                <h3>
                    Hey{uname !== "" ? ", " + uname : ""}!
                </h3>
            </div>
            <div className="menuItemsList">
                {
                    menuItems.map(menuItem =>
                        <UserMenuItem Title={menuItem.title} Subtitle={menuItem.subtitle} Icon={menuItem.icon} Action={menuItem.action} />
                    )
                }
            </div>
        </div>
    )
}

export default ProfileMenu