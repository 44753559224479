import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { HiOutlineArrowLeft } from 'react-icons/hi'
import { BsCurrencyRupee } from 'react-icons/bs'

import './reg-styles.css'

function Register() {
    const navigate = useNavigate();

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const [transactiontype, setTransactiontype] = useState(query.get('t'))

    function toTitleCase(str) { return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }); }

    return (
        <div className='subpage registerTransactionContainer'>
            <form className='transactionDetails'>
                <select className='transactionType' defaultValue={transactiontype} onChange={(e) => {
                    setTransactiontype(e.target.value)
                }}>
                    <option value={'expense'}>
                        Expense
                    </option>
                    <option value={'refund'}>
                        Refund
                    </option>
                </select>
                <div className="inputIconed">
                    <div className="icon">
                        <BsCurrencyRupee />
                    </div>
                    <input type="text" placeholder='Transacted Amount' pattern='^\d+(\.|\,)\d{2}$' />
                </div>

                <div className='twocolumninputs'>
                    <select className='spendCategory' defaultValue={'0'}>
                        <option  value={'0'} disabled>
                            Category
                        </option>
                        <option value={'food'}>
                            Food
                        </option>
                        <option value={'decor'}>
                            Decor
                        </option>
                    </select>
                    <input type="date" name="transactionDate" id="transactionDate" />
                </div>
            </form>
            <button className='alt'>
                Add {toTitleCase(transactiontype)}
            </button>
        </div>
    )
}

export default Register