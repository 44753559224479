import React from 'react'
import { HiPlus, HiOutlineEye, HiOutlineRefresh } from 'react-icons/hi'
import { TbArrowsExchange2 } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import TransactionItem from '../components/TransactionItem';

function Summary(props) {
    const navigate = useNavigate();
    return (
        <div className='subpage financesSummaryContainer'>
            <div className="greetings">
                <h3>
                    <TbArrowsExchange2 />Transactions
                </h3>
                <div className="buttonGroup">
                    <button className='icon overlay' onClick={() => {
                        navigate('/finances/register-transaction?t=expense')
                    }}> <HiPlus /> </button>

                </div>
            </div>

            <div className="monthlySummary">

            </div>

            <div className='transactionList'>
                {
                    Array(10).join().split(',').map((_, ind) => {
                        return <TransactionItem key={ind} isExpense={ind % 2 === 0} amount={20.00} Category={'Rent and Maintainance'} />
                    })
                }
            </div>
        </div>
    )
}

export default Summary