import logo from './icons/Xpense_NX192.png';
import { useMediaQuery } from 'react-responsive'
import { HiOutlineHome, HiOutlineLibrary, HiOutlineUserCircle } from 'react-icons/hi'
import { BsChevronLeft } from 'react-icons/bs'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import { MdCheck, MdClose } from 'react-icons/md'
import { HiOutlineRefresh } from 'react-icons/hi'

import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Home from './home';
import Finances from './finances';
import Profile from './profile';
import Register from './finances/subpages/Register';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import Login from './login';
import AlertMobile from './components/AlertMobile';
import ProfileMenu from './profile/subpages/ProfileMenu';
import UserProfile from './profile/subpages/UserProfile';
import Accounts from './profile/subpages/Accounts';
import Summary from './finances/subpages/Summary';

const Desktop = ({ children }) => {
	const isDesktop = useMediaQuery({ minWidth: 992 })
	return isDesktop ? children : null
}
const Tablet = ({ children }) => {
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
	return isTablet ? children : null
}
const Mobile = ({ children }) => {
	const isMobile = useMediaQuery({ maxWidth: 767 })
	return isMobile ? children : null
}
const Default = ({ children }) => {
	const isNotMobile = useMediaQuery({ minWidth: 768 })
	return isNotMobile ? children : null
}

function App() {
	const navigate = useNavigate();

	const [isAlertOpen, setIsAlertOpen] = useState(false)
	const [alertData, setAlertData] = useState({
		title: 'Alert!',
		body: 'Alert Body.',
		closeActionText: 'Close'
	})

	const RaiseAlert = (alertData) => {
		setAlertData(alertData)
		setIsAlertOpen(true)
	}

	const [pageEditMode, setPageEditMode] = useState(false);

	const pageMode = {
		'normal': {
			actionBtns: <></>
		},
		'refresh': {
			actionBtns: <div className='buttonGroup'>
				<button title='Refresh' className="icon title" onClick={(e) => {
					// console.log(e.target)
					e.target.classList.remove('refreshing')
					setTimeout(() => {
						e.target.classList.add('refreshing')
					}, 1);
					refreshActionHandlers.refreshAction();
				}}>
					<HiOutlineRefresh />
				</button>
			</div>
		},
		'edit': {
			actionBtns: pageEditMode ?
				<div className='buttonGroup'>
					<button title='Cancel' className="icon title" onClick={() => {
						editActionHandlers.cancelAction();
						setPageEditMode(false);
					}}>
						<MdClose />
					</button>
					<button title='Submit' className="icon title" onClick={() => {
						const res = editActionHandlers.submitAction();
						if (res) {
							setPageEditMode(false);
						}
					}}>
						<MdCheck />
					</button>
				</div> :
				<div className='buttonGroup'>
					<button title='EditMode' className="icon title" onClick={() => {
						setPageEditMode(true);
						editActionHandlers.editModeToggleAction();
					}}>
						<HiOutlinePencilAlt />
					</button>
				</div>
		}
	}

	const [editActionHandlers, setEditActionHandlers] = useState({
		editModeToggleAction: () => {
		},
		submitAction: () => {
			RaiseAlert({
				title: 'Submitting...',
				closeActionText: 'Ok'
			})

			return true
		},
		cancelAction: () => {
			RaiseAlert({
				title: 'Cancelled',
				alertType: 'Error',
				closeActionText: 'Ok'
			})
		}
	})

	const [refreshActionHandlers, setRefreshActionHandlers] = useState({
		refreshAction: () => {
			RaiseAlert({
				title: 'Refreshing...',
				closeActionText: 'Ok'
			})
		}
	})

	function goBack() {
		navigate(-1)
	}

	const pageTitles = {
		'/login': {
			canGoBack: false,
			titleText: null,
			title: null,
			pageMode: 'normal'
		},
		'/': {
			canGoBack: false,
			titleText: "Home",
			title: <>Home</>,
			pageMode: 'normal'
		},
		'/finances': {
			canGoBack: false,
			titleText: "Finances",
			title: <>Finances</>,
			pageMode: 'refresh'
		},
		'/finances/register-transaction': {
			canGoBack: true,
			titleText: "Register Transaction",
			title: <>
				<small>Add Transaction</small>
			</>,
			pageMode: 'normal'
		},
		'/profile': {
			canGoBack: false,
			titleText: "Preferences",
			title: <>Preferences</>,
			pageMode: 'normal'
		},
		'/profile/user': {
			canGoBack: true,
			titleText: "User Profile",
			title: <>
				<small>User Profile</small>
			</>,
			pageMode: 'edit'
		},
		'/profile/accounts': {
			canGoBack: true,
			titleText: "Accounts",
			title: <>
				<small>Accounts</small>
			</>,
			pageMode: 'normal'
		}
	}

	const footerIndicatorClassName = () => {
		if (window.location.pathname === '/') {
			return "footerIndicatorGradient left"
		}
		else if (window.location.pathname.startsWith('/finances')) {
			return "footerIndicatorGradient middle"
		}
		else if (window.location.pathname.startsWith('/profile')) {
			return "footerIndicatorGradient right"
		}
		return "footerIndicatorGradient left"
	}

	return (
		<div className="App">
			<Mobile>
				{
					<AlertMobile isOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} alertData={alertData} />
				}
				<div className={footerIndicatorClassName()}>

				</div>
				{pageTitles[window.location.pathname].title && 
					<div className="pagetitle">
						<h2>
							<span className={pageTitles[window.location.pathname].canGoBack ? 'backButton' : 'collapseBackButton'} >
								{
									pageTitles[window.location.pathname].canGoBack &&
									<button className="title icon" onClick={() => {
										goBack();
									}}>
										<BsChevronLeft />
									</button>
								}
							</span>
							<span key={window.location.pathname}>
								{
									pageTitles[window.location.pathname].title
								}
							</span>
						</h2>
						{pageMode[pageTitles[window.location.pathname].pageMode].actionBtns}
					</div>
				}
				<div className="Content"> {/* {...handlers}> */}
					<Routes>
						<Route path='/login' element={<Login RaiseAlert={RaiseAlert} setIsAlertOpen={setIsAlertOpen} />} />

						<Route path='/' element={<Home RaiseAlert={RaiseAlert} setIsAlertOpen={setIsAlertOpen} />} />

						<Route path='/profile' element={<Profile RaiseAlert={RaiseAlert} setIsAlertOpen={setIsAlertOpen} />}>
							<Route path='/profile/' element={<ProfileMenu RaiseAlert={RaiseAlert} setIsAlertOpen={setIsAlertOpen} />} />
							<Route path='/profile/user' element={<UserProfile editMode={pageEditMode} setEditActionHandlers={setEditActionHandlers} RaiseAlert={RaiseAlert} setIsAlertOpen={setIsAlertOpen} />} />
							<Route path='/profile/accounts' element={<Accounts RaiseAlert={RaiseAlert} setIsAlertOpen={setIsAlertOpen} />} />

						</Route>

						<Route path='/finances' element={<Finances RaiseAlert={RaiseAlert} setIsAlertOpen={setIsAlertOpen} />}>
							<Route path='/finances' element={<Summary RaiseAlert={RaiseAlert} setIsAlertOpen={setIsAlertOpen} />} />
							<Route path='/finances/register-transaction' element={<Register />} />
						</Route>
					</Routes>
				</div>
				<footer className={window.location.pathname.startsWith('/login') || window.location.pathname.startsWith('/usermgmt') ? 'collapseFooter' : ''}>
					<button title={pageTitles['/'].titleText} className={window.location.pathname == '/' ? 'menuButton active' : 'menuButton'}
						onClick={() => {
							navigate('/')
						}}
					>
						<HiOutlineHome />
					</button>
					<button title={pageTitles['/finances'].titleText} className={window.location.pathname.startsWith('/finances') ? 'menuButton active' : 'menuButton'}
						onClick={() => {
							navigate('/finances')
						}}
					>
						<HiOutlineLibrary />
					</button>
					<button title={pageTitles['/profile'].titleText} className={window.location.pathname.startsWith('/profile') ? 'menuButton active' : 'menuButton'}
						onClick={() => {
							navigate('/profile')
						}}
					>
						<HiOutlineUserCircle />
					</button>
				</footer>
			</Mobile>
		</div>
	);
}

export default App;
