export const authErrorCodes = {
    "auth/admin-restricted-operation": "ADMIN ONLY OPERATION",
    "auth/argument-error": "ARGUMENT ERROR",
    "auth/app-not-authorized": "APP NOT AUTHORIZED",
    "auth/app-not-installed": "APP NOT INSTALLED",
    "auth/captcha-check-failed": "CAPTCHA CHECK FAILED",
    "auth/code-expired": "CODE EXPIRED",
    "auth/cordova-not-ready": "CORDOVA NOT READY",
    "auth/cors-unsupported": "CORS UNSUPPORTED",
    "auth/credential-already-in-use": "CREDENTIAL ALREADY IN USE",
    "auth/custom-token-mismatch": "CREDENTIAL MISMATCH",
    "auth/requires-recent-login": "CREDENTIAL TOO OLD LOGIN AGAIN",
    "auth/dependent-sdk-initialized-before-auth": "DEPENDENT SDK INIT BEFORE AUTH",
    "auth/dynamic-link-not-activated": "DYNAMIC LINK NOT ACTIVATED",
    "auth/missing-email": "USER EMAIL MISSING",
    "auth/email-change-needs-verification": "EMAIL CHANGE NEEDS VERIFICATION",
    "auth/email-already-in-use": "EMAIL EXISTS",
    "auth/emulator-config-failed": "EMULATOR CONFIG FAILED",
    "auth/expired-action-code": "EXPIRED OOB CODE",
    "auth/cancelled-popup-request": "EXPIRED POPUP REQUEST",
    "auth/internal-error": "INTERNAL ERROR",
    "auth/invalid-api-key": "INVALID API KEY",
    "auth/invalid-app-credential": "INVALID APP CREDENTIAL",
    "auth/invalid-app-id": "INVALID APP ID",
    "auth/invalid-user-token": "INVALID AUTH",
    "auth/invalid-auth-event": "INVALID AUTH EVENT",
    "auth/invalid-cert-hash": "INVALID CERT HASH",
    "auth/invalid-verification-code": "INVALID CODE",
    "auth/invalid-continue-uri": "INVALID CONTINUE URI",
    "auth/invalid-cordova-configuration": "INVALID CORDOVA CONFIGURATION",
    "auth/invalid-custom-token": "INVALID CUSTOM TOKEN",
    "auth/invalid-dynamic-link-domain": "INVALID DYNAMIC LINK DOMAIN",
    "auth/invalid-email": "INVALID EMAIL",
    "auth/invalid-emulator-scheme": "INVALID EMULATOR SCHEME",
    "auth/invalid-credential": "INVALID IDP RESPONSE",
    "auth/invalid-message-payload": "INVALID MESSAGE PAYLOAD",
    "auth/invalid-multi-factor-session": "INVALID MFA SESSION",
    "auth/invalid-oauth-client-id": "INVALID OAUTH CLIENT ID",
    "auth/invalid-oauth-provider": "INVALID OAUTH PROVIDER",
    "auth/invalid-action-code": "INVALID OOB CODE",
    "auth/unauthorized-domain": "INVALID ORIGIN",
    "auth/wrong-password": "INVALID PASSWORD",
    "auth/invalid-persistence-type": "INVALID PERSISTENCE",
    "auth/invalid-phone-number": "INVALID PHONE NUMBER",
    "auth/invalid-provider-id": "INVALID PROVIDER ID",
    "auth/invalid-recipient-email": "INVALID RECIPIENT EMAIL",
    "auth/invalid-sender": "INVALID SENDER",
    "auth/invalid-verification-id": "INVALID SESSION INFO",
    "auth/invalid-tenant-id": "INVALID TENANT ID",
    "auth/multi-factor-info-not-found": "MFA INFO NOT FOUND",
    "auth/multi-factor-auth-required": "MFA REQUIRED",
    "auth/missing-android-pkg-name": "MISSING ANDROID PACKAGE NAME",
    "auth/missing-app-credential": "MISSING APP CREDENTIAL",
    "auth/auth-domain-config-required": "MISSING AUTH DOMAIN",
    "auth/missing-verification-code": "MISSING CODE",
    "auth/missing-continue-uri": "MISSING CONTINUE URI",
    "auth/missing-iframe-start": "MISSING IFRAME START",
    "auth/missing-ios-bundle-id": "MISSING IOS BUNDLE ID",
    "auth/missing-or-invalid-nonce": "MISSING OR INVALID NONCE",
    "auth/missing-multi-factor-info": "MISSING MFA INFO",
    "auth/missing-multi-factor-session": "MISSING MFA SESSION",
    "auth/missing-phone-number": "MISSING PHONE NUMBER",
    "auth/missing-verification-id": "MISSING SESSION INFO",
    "auth/app-deleted": "MODULE DESTROYED",
    "auth/account-exists-with-different-credential": "NEED CONFIRMATION",
    "auth/network-request-failed": "NETWORK REQUEST FAILED",
    "auth/null-user": "NULL USER",
    "auth/no-auth-event": "NO AUTH EVENT",
    "auth/no-such-provider": "NO SUCH PROVIDER",
    "auth/operation-not-allowed": "OPERATION NOT ALLOWED",
    "auth/operation-not-supported-in-this-environment": "OPERATION NOT SUPPORTED",
    "auth/popup-blocked": "POPUP BLOCKED",
    "auth/popup-closed-by-user": "POPUP CLOSED BY USER",
    "auth/provider-already-linked": "PROVIDER ALREADY LINKED",
    "auth/quota-exceeded": "QUOTA EXCEEDED",
    "auth/redirect-cancelled-by-user": "REDIRECT CANCELLED BY USER",
    "auth/redirect-operation-pending": "REDIRECT OPERATION PENDING",
    "auth/rejected-credential": "REJECTED CREDENTIAL",
    "auth/second-factor-already-in-use": "SECOND FACTOR ALREADY ENROLLED",
    "auth/maximum-second-factor-count-exceeded": "SECOND FACTOR LIMIT EXCEEDED",
    "auth/tenant-id-mismatch": "TENANT ID MISMATCH",
    "auth/timeout": "TIMEOUT",
    "auth/user-token-expired": "TOKEN EXPIRED",
    "auth/too-many-requests": "TOO MANY ATTEMPTS TRY LATER",
    "auth/unauthorized-continue-uri": "UNAUTHORIZED DOMAIN",
    "auth/unsupported-first-factor": "UNSUPPORTED FIRST FACTOR",
    "auth/unsupported-persistence-type": "UNSUPPORTED PERSISTENCE",
    "auth/unsupported-tenant-operation": "UNSUPPORTED TENANT OPERATION",
    "auth/unverified-email": "UNVERIFIED EMAIL",
    "auth/user-cancelled": "USER CANCELLED",
    "auth/user-not-found": "USER DELETED",
    "auth/user-disabled": "USER DISABLED",
    "auth/user-mismatch": "USER MISMATCH",
    "auth/user-signed-out": "USER SIGNED OUT",
    "auth/weak-password": "WEAK PASSWORD",
    "auth/web-storage-unsupported": "WEB STORAGE UNSUPPORTED",
    "auth/already-initialized": "ALREADY INITIALIZED",
    "auth/recaptcha-not-enabled": "RECAPTCHA NOT ENABLED",
    "auth/missing-recaptcha-token": "MISSING RECAPTCHA TOKEN",
    "auth/invalid-recaptcha-token": "INVALID RECAPTCHA TOKEN",
    "auth/invalid-recaptcha-action": "INVALID RECAPTCHA ACTION",
    "auth/missing-client-type": "MISSING CLIENT TYPE",
    "auth/missing-recaptcha-version": "MISSING RECAPTCHA VERSION",
    "auth/invalid-recaptcha-version": "INVALID RECAPTCHA VERSION",
    "auth/invalid-req-type": "INVALID REQ TYPE"
}