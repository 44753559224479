import React, { useEffect, useState } from 'react'
import './styles.css'

import logo from '../icons/Xpense_NX192.png';
import { BsChevronLeft } from 'react-icons/bs'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import { MdCheck, MdClose } from 'react-icons/md'

import { isUserSignedIn } from '../firebase'
import { Outlet, useNavigate } from 'react-router-dom'

function Profile(props) {
	const navigate = useNavigate();

	const [subPageEditMode, setsubPageEditMode] = useState(false);
	const [subPageConfirm, setSubPageConfirm] = useState(false);
	const [subPageReject, setSubPageReject] = useState(false);

	useEffect(() => {
		props.setIsAlertOpen(false);
		startUpCheck();

		return () => {

		}
	}, [])

	async function startUpCheck() {
		const signIncheck = await isUserSignedIn();
		if (signIncheck == null) {
			navigate('/login')
		}
	}


	return (
		<div className='page profileContainer'>
			<Outlet context={[subPageEditMode, setsubPageEditMode, subPageConfirm, subPageReject]} />
		</div>
	)
}

export default Profile