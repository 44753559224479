// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { GoogleAuthProvider, getAuth, signInWithEmailAndPassword, signInWithPopup, signOut, updateProfile } from "firebase/auth";

import { authErrorCodes } from './firebaseErrorMessages';
import { toTitleCase } from './helpers/stringHelpers';

const firebaseConfig = {
  apiKey: "AIzaSyDM6b0Mp8Q7XUSu7vh_LocrOFrSxxTBtC0",
  authDomain: "xpensenx.firebaseapp.com",
  projectId: "xpensenx",
  storageBucket: "xpensenx.appspot.com",
  messagingSenderId: "589691026057",
  appId: "1:589691026057:web:c06db98b4a6670a30f2458",
  measurementId: "G-NDEWK3921V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Authentication
const auth = getAuth();

export async function signInUser(email, password) {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password)
        // Signed in 
        const user = userCredential.user;
        return [true, user]
        // ...
    }
    catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;

        return [false, toTitleCase(authErrorCodes[ errorCode ])]
    };
}

export async function signInUserWithGoogle() {
    try {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: "select_account"
          });

        auth.useDeviceLanguage();

        const result = await signInWithPopup(auth, provider);
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // Signed in 
        const user = result.user;
        return [true, user]
        // ...
    }
    catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;

        return [false, toTitleCase(authErrorCodes[ errorCode ])]
    };
}

export async function isUserSignedIn() {
    try {
        await new Promise((resolve, reject) =>
            auth.onAuthStateChanged(
                user => {
                    if (user) {
                        // User is signed in.
                        resolve(user)
                    } else {
                        // No user is signed in.
                        reject('no user logged in')
                    }
                },
                // Prevent console error
                error => reject(error)
            )
        )
        return auth.currentUser.email
    } catch (error) {
        return null
    }
}

export async function getCurrentUserDisplayName() {
    try {
        await new Promise((resolve, reject) =>
            auth.onAuthStateChanged(
                user => {
                    if (user) {
                        // User is signed in.
                        resolve(user)
                    } else {
                        // No user is signed in.
                        reject('no user logged in')
                    }
                },
                // Prevent console error
                error => reject(error)
            )
        )
        return auth.currentUser.displayName
    } catch (error) {
        return null
    }
}

export async function signOutUser() {
    try {
        const userCredential = await signOut(auth);
        // Signed Out
        return true
    }
    catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;

        return toTitleCase(authErrorCodes[ errorCode ])
    };
}

// Firestore - User Data

export async function setCurrentUserDisplayName(userName){
    try {
        const res = await updateProfile(
            auth.currentUser, 
            {
                displayName: userName
            }
        )
        return true
    } catch (error) {
        return false
    }
} 

// Firestore - Expense Data
