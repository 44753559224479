import React from 'react'
import { BiHomeSmile } from 'react-icons/bi'
import { BsCurrencyRupee } from 'react-icons/bs'
import {IoFastFoodOutline} from 'react-icons/io5'
import {LiaFileInvoiceSolid} from 'react-icons/lia'
import {MdOutlineLocalGroceryStore} from 'react-icons/md'
import {RiShoppingBag3Line} from 'react-icons/ri'

function TransactionItem(props) {

    const transactionIcon = (cat)=>{
        switch (cat) {
            case "Food":
                return <IoFastFoodOutline />
                break;
            case "Bills":
                return <LiaFileInvoiceSolid />
                break;
            case "Groceries":
                return <MdOutlineLocalGroceryStore />
            case "Clothes and Fashion":
                return <RiShoppingBag3Line />
            case "Rent and Maintainance":
                return <BiHomeSmile />
            default:
                return <BsCurrencyRupee />
                break;
        }
    }

  return (
    <div className='transactionItem'>
        <span className='left'>
            {transactionIcon(props.Category)}
            <div>
                <span style={{
                    fontWeight: 600
                }}>
                    Transaction Item
                </span> <br />
                <small style={{
                    opacity: 0.5,
                    fontWeight: 500
                }}>
                    Date Time, and Description
                </small>
            </div>
        </span>
        <span className={props.isExpense ? "expenseAmount" : "refundAmount"} >
            {props.isExpense ? "-" : "+"} ₹ {props.amount.toFixed(2)}
        </span>
    </div>
  )
}

export default TransactionItem