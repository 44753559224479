import React, { useEffect, useState } from 'react'

import { HiOutlineUserCircle } from 'react-icons/hi'
import { useOutletContext } from 'react-router-dom';
import { getCurrentUserDisplayName, setCurrentUserDisplayName } from '../../firebase';

function UserProfile( {editMode, setEditActionHandlers, RaiseAlert} ) {

	useEffect(() => {
		loadValues();

		setEditActionHandlers({
			editModeToggleAction : () => {},
			submitAction: () =>{
				updateValues();
			},
			cancelAction: () => {
				loadValues();
			}
		})
	}, [])

	const updateValues = async ( ) => {
		const fullNameElement = document.getElementById('userName');
		const nickNameElement = document.getElementById('userNickName');

		const res = await setCurrentUserDisplayName(fullNameElement.value)
		if(res){
			RaiseAlert({
				title: 'Updated Profile',
				body: null,
				closeActionText: "Done",
				alertType: 'Success'
			})
		}
		else{
			RaiseAlert({
				title: 'Error Updated Profile',
				body: null,
				closeActionText: "Retry",
				alertType: 'Error'
			})
		}
	}

	const loadValues = async () => {
		const fullNameElement = document.getElementById('userName');
		const nickNameElement = document.getElementById('userNickName');

		fullNameElement.value = await getCurrentUserDisplayName();
	}

	return (
		<div className='subpage UserProfilePage'>
			<div className="title">
				<h3>
					Display Name
				</h3>
			</div>
			<div className={editMode ? "inputIconed" : "inputIconed disabled"}>
				<div className="icon">
					<HiOutlineUserCircle />
				</div>
				<input id='userName' required type="text" placeholder='Name' autocomplete="off" list="autocompleteOff" />
			</div>
			<div className="title">
				<h3>
					Nickname
				</h3>
			</div>
			<div className={editMode ? "inputIconed" : "inputIconed disabled"}>
				<div className="icon">
					<HiOutlineUserCircle />
				</div>
				<input id='userNickName' type="text" placeholder={editMode ? 'Nick Name (Optional)' : '-- Not Set --'} autocomplete="off" list="autocompleteOff" />
			</div>
		</div>
	)
}

export default UserProfile