import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { BsChevronLeft } from 'react-icons/bs'

import './style.css'
import { isUserSignedIn } from '../firebase';

function Finances(props) {
	const navigate = useNavigate();

	useEffect(() => {
		props.setIsAlertOpen(false);
		startUpCheck();

		return () => {

		}
	}, [])

	async function startUpCheck() {
		const signIncheck = await isUserSignedIn();
		if (signIncheck == null) {
			navigate('/login')
		}
	}

	function goBack() {
		// setsubPageEditMode(false)
		// setSubPageConfirm(false)
		// setSubPageReject(false)
		navigate(-1)
	}

	const refreshTransactions = () => {
		return new Promise(res => {
			alert('Refreshing...')
			setTimeout(() => {
				alert('Refreshed.')
			}, 1500);
		});
	}

	return (
		<div className='page financesContainer'>
			<Outlet />
		</div>
	)
}

export default Finances