import React from 'react'
import Popup from 'reactjs-popup'
import {BsCheck2Circle} from 'react-icons/bs'
import {MdOutlineErrorOutline} from 'react-icons/md'

import './alertmobile.css'

function AlertMobile(props) {

    const alertTypeIcon = () => {
        switch (props.alertData.alertType) {
            case 'Success':
                return <BsCheck2Circle className={'Success'} />
                break;
            case 'Error':
                return <MdOutlineErrorOutline className={'Error'} />
                break;
        
            default:
                return <>{props.alertData.alertType}</>
                break;
        }
    }

    return (
        <Popup modal position="center" open={props.isOpen} onClose={() => {
            props.setIsAlertOpen(false);
        }}
            closeOnDocumentClick={props.alertData.closeActionText ? true : false}
            closeOnEscape={props.alertData.closeActionText ? true : false}
        >
            {
                close =>
                    <div className='alertMobileContainer'>
                        <span className='alertMobileTitle'>
                            {alertTypeIcon()}
                            {props.alertData.title}
                        </span>
                        {
                            props.alertData.body &&
                            <span className='alertMobileBody'>
                                {props.alertData.body}
                            </span>
                        }
                        {
                            props.alertData.closeActionText &&
                            <span className='alertMobileActions'>
                                <button onClick={close}>
                                    {props.alertData.closeActionText}
                                </button>
                            </span>
                        }
                    </div>
            }
        </Popup>
    )
}

export default AlertMobile