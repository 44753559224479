import React from 'react'

function UserMenuItem(props) {
  return (
    <div className='userMenuItem' onClick={
        ()=>{
            // alert("User Menu Action!")
            props.Action();
        }}>
        <div className="icon">
            {props.Icon}
        </div>
        <span className='userMenuItemText'>
            <span className='userMenuItemTitle'>
                {props.Title}
            </span>
            <span className='userMenuItemDescription'>
                {props.Subtitle}
            </span>
        </span>
    </div>
  )
}

export default UserMenuItem