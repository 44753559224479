import React from 'react'
import Popup from 'reactjs-popup';

import { AiOutlinePieChart } from 'react-icons/ai'
import { HiOutlineArrowLeft } from 'react-icons/hi'

function UpdateLimits() {
  return (
    <Popup trigger={
        <button>
            <AiOutlinePieChart />
            Update Limits
        </button>
    }
    modal
    >
        {close => 
            <div className='modal'>
                <h4 className='modal-header'>
                    <button className='overlay icon' onClick={close}>
                        <HiOutlineArrowLeft />
                    </button>
                    Update Spend Limit
                </h4>
                Update Limit here.
            </div>
        }
    </Popup>
  )
}

export default UpdateLimits